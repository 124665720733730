<template>
    <questions-list
        shared-type="group"
        :shared-uuid="$auth.member.group_uuid"
        passed-type="student"
        :passed-uuid="$auth.user.uuid"
        hide-date
        hide-default-header
    />
</template>

<script>
import QuestionsList from '@apps/questions/components/QuestionsList'
export default {
    name: 'QuestionsActivePage',
    components: {
        QuestionsList
    }
}
</script>

<style lang=scss>

</style>
